import React from 'react'
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Fade from 'react-reveal/Fade';
import MyTable from '../Components/MyTable/MyTable_2';
import MyTable2 from '../Components/MyTable/MyTable_3';
import MyTable3 from '../Components/MyTable/MyTable_4';
import MyTable4 from '../Components/MyTable/MyTable_5';
import MyTable5 from '../Components/MyTable/MyTable_6';


const data1 = [
  { szemelyes_adat: "Elméleti oktatás", adatkezeles_celja: "45 000 Ft" },
  { szemelyes_adat: "Gyakorlati képzés ( 10 +1 óra x  9 500 Ft/óra)", adatkezeles_celja: "104 500 Ft" },
  { szemelyes_adat: "Elméleti vizsga díja", adatkezeles_celja: "10 500 Ft" },
  { szemelyes_adat: "Járműkezelési vizsga díja", adatkezeles_celja: "7 200 Ft" },
  { szemelyes_adat: "Forgalmi vizsga díja", adatkezeles_celja: "7 200 Ft" },
  { szemelyes_adat: "Összesen", adatkezeles_celja: "174 400 Ft" },
]

const data2 = [
  { szemelyes_adat: "Elméleti oktatás", adatkezeles_celja: "49 000 Ft" },
  { szemelyes_adat: "Gyakorlati képzés (16 +1 x 9 500 Ft/óra)", adatkezeles_celja: "161 500 Ft" },
  { szemelyes_adat: "B-vel  gyakorlati óra ( 2 +1 x 9 500 Ft/óra)", adatkezeles_celja: "28 500 Ft" },
  { szemelyes_adat: "Elméleti vizsga díja", adatkezeles_celja: "10 500 Ft" },
  { szemelyes_adat: "Járműkezelési vizsga díja", adatkezeles_celja: "9 000 Ft" },
  { szemelyes_adat: "Forgalmi vizsga díja", adatkezeles_celja: "24 000 Ft" },
  { szemelyes_adat: "Összesen", adatkezeles_celja: "254 000 Ft" },
  { szemelyes_adat: "B-vel összesen", adatkezeles_celja: "121 000 Ft" },
]

const data3 = [
  { szemelyes_adat: "Elméleti oktatás", adatkezeles_celja: "49 000 Ft" },
  { szemelyes_adat: "Gyakorlati képzés (16 +1 x 9 500 Ft/óra)", adatkezeles_celja: "161 500 Ft" },
  { szemelyes_adat: "2 évnél korábbi A1-el ( 12+1 óra x 9 500 Ft/óra)", adatkezeles_celja: "123 500 Ft" },
  { szemelyes_adat: "2 évnél régebbi A1-el (8+1 óra x 9 500 Ft/óra)", adatkezeles_celja: "85 500 Ft" },
  { szemelyes_adat: "Elméleti vizsga díja", adatkezeles_celja: "10 500 Ft" },
  { szemelyes_adat: "Járműkezelési vizsga díja", adatkezeles_celja: "9 000 Ft" },
  { szemelyes_adat: "Forgalmi vizsga díja", adatkezeles_celja: "24 000 Ft" },
  { szemelyes_adat: "Összesen", adatkezeles_celja: "254 000 Ft" },
  { szemelyes_adat: "Összesen 2 évnél fiatalabb A1-el", adatkezeles_celja: "156 500 Ft" },
  { szemelyes_adat: "Összesen 2 évnél régebbi A1-el", adatkezeles_celja: "178 000 Ft" },
]

const data4 = [
  { szemelyes_adat: "Elméleti oktatás", adatkezeles_celja: "49 000 Ft" },
  { szemelyes_adat: "Gyakorlati képzés (26 +1 x 9 500 Ft/óra)", adatkezeles_celja: "256 500 Ft" },
  { szemelyes_adat: "2 évnél korábbi A1-el  ( 16 + 1 óra x 9 500 Ft/óra)", adatkezeles_celja: "161 500 Ft" },
  { szemelyes_adat: "2 évnél korábbi A2-vel (12+1 óra x 9 500 Ft/óra)", adatkezeles_celja: "123 500 Ft" },
  { szemelyes_adat: "2 évnél régebbi A1-el (10+1 óra x 9 500 Ft/ óra)", adatkezeles_celja: "104 500 Ft" },
  { szemelyes_adat: "2 évnél régebbi A2-vel ( 8 +1 óra x 9 500 Ft/óra)", adatkezeles_celja: "85 500 Ft" },
  { szemelyes_adat: "Kresz vizsga", adatkezeles_celja: "10 500 Ft" },
  { szemelyes_adat: "Járműkezelési rutin vizsga", adatkezeles_celja: "9 000 Ft" },
  { szemelyes_adat: "Forgalmi vizsga", adatkezeles_celja: "24 000 Ft" },
  { szemelyes_adat: "Összesen", adatkezeles_celja: "349 000 Ft" },
  { szemelyes_adat: "2 évnél korábbi A1-el ( csak gyakorlat)", adatkezeles_celja: "194 500 Ft" },
  { szemelyes_adat: "2 évnél korábbi A2-vel ( csak gyakorlat )", adatkezeles_celja: "156 500 Ft" },
  { szemelyes_adat: "2 évnél régebbi A1-el", adatkezeles_celja: "197 000 Ft" },
  { szemelyes_adat: "2 évnél régebbi A2-vel", adatkezeles_celja: "178 000 Ft" },
]

const data5 = [
  { szemelyes_adat: "Alapismeretek", adatkezeles_celja: "Közlekedési alapismeretek, Járművezetés elmélete", adatkezeles_celja2: "22 óra" },
  { szemelyes_adat: "Járművezetési gyakorlat", adatkezeles_celja: "Alapoktatás 4 óra, Főoktatás 6 óra", adatkezeles_celja2: "10 óra" },
  { szemelyes_adat: "Vizsga", adatkezeles_celja: "", adatkezeles_celja2: "1 óra" },
  { szemelyes_adat: "Összesen:", adatkezeles_celja: "", adatkezeles_celja2: "27 óra" }
]

const data6 = [
  { szemelyes_adat: "Alapismeretek, KRESZ (B kategóriával 3 óra)", adatkezeles_celja: "Közlekedési alapismeretek, Járművezetés elmélete Szerkezeti és üzemeltetési ismeretek", adatkezeles_celja2: "16 óra" },
  { szemelyes_adat: "Járművezetési gyakorlat (B kategóriával 2 óra)", adatkezeles_celja: "Alapoktatás 4 óra, Főoktatás 10 óra", adatkezeles_celja2: "16 óra" },
  { szemelyes_adat: "Forgalmi vizsga", adatkezeles_celja: "", adatkezeles_celja2: "1 óra" },
  { szemelyes_adat: "Összesen:", adatkezeles_celja: "", adatkezeles_celja2: "29 óra" }
]

const data7 = [
  { szemelyes_adat: "Alapismeretek (0 óra 2 évnél fiatalabb A1-gyel, 3 óra 2 évnél régebbi A1-gyel)", adatkezeles_celja: "Közlekedési alapismeretek, Járművezetés elmélete, Szerkezeti és üzemeltetési ismeretek", adatkezeles_celja2: "22 óra" },
  { szemelyes_adat: "Járművezetési gyakorlat (4+8 óra, min.180km 2 évnél fiatalabb A1-gyel, 2+6 óra, min.120km 2 évnél régebbi A1-gyel)", adatkezeles_celja: "Alapoktatás 6 óra, Forgalom 10 óra (min. 240 km)", adatkezeles_celja2: "16 óra" },
  { szemelyes_adat: "Forgalmi vizsga", adatkezeles_celja: "", adatkezeles_celja2: "1 óra" },
  { szemelyes_adat: "Összesen:", adatkezeles_celja: "", adatkezeles_celja2: "39 óra" }
]

const data8 = [
  { szemelyes_adat: "Alapismeretek (0 óra 2 évnél fiatalabb A1-gyel, 3 óra 2 évnél régebbi A1-gyel, 0 óra 2 évnél fiatalabb A2-vel, 3 óra 2 évnél régebbi A2-vel)", adatkezeles_celja: "Közlekedési alapismeretek, Járművezetés elmélete, Szerkezeti és üzemeltetési ismeretek", adatkezeles_celja2: "22 óra" },
  { szemelyes_adat: "Járművezetési gyakorlat (6+10 óra, min.240km 2 évnél fiatalabb A1-gyel, 4+6 óra min.150km 2 évnél régebbi A1-gyel, 4+8 óra min.180km 2 évnél fiatalabb A2-vel, 2+6 óra min.120km 2 évnél régebbi A2-vel)", adatkezeles_celja: "Alapoktatás 10 óra, Forgalom 16 óra (min. 240 km)", adatkezeles_celja2: "26 óra" },
  { szemelyes_adat: "Forgalmi vizsga", adatkezeles_celja: "", adatkezeles_celja2: "1 óra" },
  { szemelyes_adat: "Összesen:", adatkezeles_celja: "", adatkezeles_celja2: "49 óra" }
]


function ACategory() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - AM, A1, A2, A kategória'
  })


  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="homePage"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className='title'>
          TÁJÉKOZTATÓ ÉS VÁLLALÁSI FELTÉTELEK "AM", "A1", "A2", "A" kategóriás tanfolyam
        </div>
        <div className='bcategory'>
        Tisztelt Ügyfelünk!<br />
        Köszönjük a bizalmát, hogy autósiskolánkat választotta tanulásához, jogosítványszerzéséhez. Szeretnénk
        tájékoztatni a motoros (AM, A1, A2, A) járművezetői tanfolyam beiskolázási feltételeiről, a tanfolyam menetéről,
        a vizsgáztatási rendjéről, az Ön illetve az iskola jogairól, kötelességeiről és egyéb szolgáltatásainkról.
        </div>
        <div className='low-title'>
          <Fade bottom>
          <p className='bcat'>
            „AM” kategória (moped)
          </p>
          </Fade>
          <Fade bottom>
          <p className='low-title-content2'>
            &emsp;•	Adott kategóriával vezethető járművek*: segédmotor ( max. 50 cm3 ), állati erővel vont jármű, kerti traktor<br />
            &emsp;(Külföldön csak 16 éves kortól lehet vezetni!)<br/>
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
          Tanfolyamra az vehető fel, aki:
          </p>
          <p className='low-title-content2'>
            &emsp;•	betöltötte 13,5 életévét<br />
            &emsp;•	Az "AM" kategória részletes leírása<br />
            Segédmotoros kerékpárok (robogók), azaz kétkerekű segédmotoros kerékpárok (L1e kategória) vagy
            háromkerekű segédmotoros kerékpárok (L2e kategória), amelyek legnagyobb tervezési sebessége 45 km/h
            (kivéve azokat, amelyek legnagyobb tervezési sebessége 25 km/h-nál kisebb vagy azzal megegyezik), és az alábbi
            jellemzőkkel rendelkeznek:<br/>
            &emsp;a) kétkerekű típusnál a motor:<br />
            &emsp;hengerűrtartalma nem nagyobb 50 cm3-nél a belsőégésű motorok esetében, vagy – folyamatosan 
            leadott legnagyobb névleges teljesítménye nem több 4 kW-nál elektromos motor esetében;<br/>
            &emsp;b) háromkerekű típusnál a motor:<br />
            – hengerűrtartalma nem nagyobb 50 cm3-nél a külső gyújtású motorok
            esetében,<br/>
            – legnagyobb hasznos teljesítménye nem több 4 kW-nál egyéb belsőégésű motorok esetében,
            vagy <br/>
            – folyamatosan leadott legnagyobb névleges teljesítménye nem több 4 kW-nál elektromos
            motorok esetében. Könnyű négykerekű motorkerékpárok, amelyek terheletlen tömege kevesebb 350
            kg-nál (L6e kategória), a 45 km/h-t meg nem haladó legnagyobb tervezési sebességű elektromos
            járművek esetében az akkumulátorok tömegét nem számítva, és amelyek – hengerűrtartalma nem
            nagyobb 50 cm3-nél a külső gyújtású motorok esetében; – legnagyobb hasznos teljesítménye nem több 4 kW-nál egyéb belsőégésű motorok esetében, vagy folyamatosan leadott legnagyobb névleges teljesítménye nem több 4 kW-nál elektromos motorok esetében.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            Az AM tanfolyam tantárgyai, minimális óraszámai
          </p>
          <MyTable5 data={data5}/>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            Vizsgák
          </p>
          <p className='low-title-content2'>
          Elmélet:&emsp; közlekedési alapismeretek számítógépes vizsga. 1 alkalom.<br />
          Gyakorlat:&emsp; járműkezelési (rutin) és forgalmi gyakorlati vizsga. 2 alkalom.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            A vizsgára bocsátás feltételei
          </p>
          <p className='low-title-content2'>
          Elméleti vizsgára az bocsátható, aki a tanfolyam elméleti részét igazoltan elvégezte; 14 évesnél legfeljebb 3
          hónappal fiatalabb. <br/>
          Gyakorlati vizsgára az bocsátható, aki elméleti vizsgatárgyból sikeresen vizsgázott; a kötelező gyakorlati órákat
          igazoltan teljesítette; a 14 évet betöltötte, kivéve járműkezelés vizsga.<br/>
          Korlátozás: AM kategóriás vezetői engedéllyel 16. életév betöltéséig csak Magyarországon lehet járművet
          vezetni.<br/>
          A tanuló az összes vizsgadíjat, illetve tandíjat, csak és kizárólag a képzőszervnél fizethti.
          </p>
          </Fade>
          <Fade bottom>
          <MyTable data={data1} />
            <br />
          <p className='low-title-content2'>
            Átvett tanulók esetében a gyakorlati óradíj 11.000 Ft.
          </p>
          <p className='low-title-content2'>
            Járműkezelési vizsgaóra díja 9.000 Ft.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat'>
            A1 kategória (motorkerékpár 125cm3)
          </p>
          <p className='low-title-content2'>
          A1 kategóriával vezethető járművek:&emsp; motorkerékpár 125cm3-ig; teljesítménye 11 kW-ig, teljesítmény / saját
          tömeg aránya 0,1 kW/kg-ig<br/>
          Tanfolyamra az vehető fel, aki:<br/>
          &emsp;• betöltötte a 15,5. életévét<br/>
          &emsp;• igazolja alapfokú iskolai végzettségét<br/>
          &emsp;+ B kategóriás jogosítvány kiegészítéseként eltérő kötelező és minimális tantervvel tanulható. B kategóriával a
          KRESZ alapismeretek minimum 3 óra, minimális gyakorlati oktatás 2 óra, ami motoros előképzettség nélkül
          általában nem tartható.<br/>
          Az A1 alkategóriára érvényesített vezetői engedéllyel vezethető még – az orvosi érvényesség lejártát követően is,
          de legfeljebb az engedély kiállítását követő tíz évig - a segédmotoros kerékpár, a lassú jármű és pótkocsija, a kerti
          traktor és az állati erővel vont jármű.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            A tanfolyam tantárgyai, minimális óraszámai
          </p>
          <MyTable5 data={data6}/>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            Vizsgák
          </p>
          <p className='low-title-content2'>
            Elmélet:&emsp; közlekedési alapismeretek számítógépes vizsga. 1 alkalom.<br />
            Gyakorlat:&emsp; járműkezelési (rutin) és forgalmi gyakorlati vizsga. 2 alkalom.<br/>
            Kivéve a B jogosítvány kiegészítése, mert akkor a járműkezelési rutin vizsga és forgalmi vizsga összevontan, 1
            alkalommal van!
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            A vizsgára bocsátás feltételei
          </p>
          <p className='low-title-content2'>
            Elméleti vizsgára az bocsátható, aki a tanfolyam elméleti részét igazoltan elvégezte; 16 évesnél legfeljebb 3
            hónappal fiatalabb. <br/>
            Gyakorlati vizsgára az bocsátható, aki elméleti vizsgatárgyból sikeresen vizsgázott; a kötelező gyakorlati órákat
            igazoltan teljesítette; a 16 évet betöltötte, kivéve járműkezelés vizsga.<br/>
            Korlátozás: Az elsőként szerzett nemzetközi kategóriás vezetői engedély a vizsga napjától számított két évig kezdő vezetői
            engedélynek minősül. A1 kategóriára érvényesített kezdő vezetői engedéllyel utas nem szállítható.<br/>
          </p>
          </Fade>
          <Fade bottom>
          <MyTable2 data={data2} />
            <br />
          <p className='low-title-content2'>
            Átvett tanulók esetében a gyakorlati óradíj 11 000 Ft!
          </p>
          <p className='low-title-content2'>
            Járműkezelési vizsgaóra díja 9.000 Ft.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat'>
            „A2” kategória (motorkerékpár-max. 35 kW)
          </p>
          <p className='low-title-content2'>
          Adott kategóriával vezethető járművek:&emsp; motorkerékpár, max. 35 kW-os teljesítményig, teljesítmény/saját tömeg
          aránya 0,2 kW/kg-ig<br/>
          Tanfolyamra az vehető fel, aki:<br/>
          &emsp;• betöltötte a 17,5 életévét<br/>
          &emsp;• igazolja alapfokú iskolai végzettségét<br/>
          &emsp;+ A1 kategóriával rendelkezők számára eltérő oktatási tanrend van, a következők szerint.<br/>
          Ha 2 évnél fiatalabb az A1 jogosítvány és annak KRESZ vizsgája, akkor KRESZ eleméleti képzésen és KRESZ elméleti
          vizsgán nem kell részt venni, csak gyakorlati oktatás van, illetve a gyakorlati kötelező alapoktatás (rutin) is
          kevesebb, 4 óra, valamint a kötelező forgalmi vezetés 8 óra.<br/>
          Ha 2 évnél régebbi az A1 jogosítvány és annak KRESZ vizsgája, akkor KRESZ eleméleti képzésen 3 órában és KRESZ
          elméleti vizsgán is részt kell venni, illetve a gyakorlati kötelező alapoktatás (rutin) még kevesebb, 2 óra, valamint
          a kötelező forgalmi vezetés szintén csak 6 óra.<br/>
          Az „A2” alkategóriára érvényesített vezetői engedély az „A1” alkategóriára és „AM” kategóriára is érvényesek,
          továbbá – segédmotoros kerékpár (ideértve a „mopedautót” is), lassú jármű és pótkocsija, kerti traktor állati
          erővel vont jármű vezetésére jogosít.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            Az tanfolyam tantárgyai, minimális óraszámai
          </p>
          <MyTable5 data={data7}/>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            Vizsgák
          </p>
          <p className='low-title-content2'>
            Elmélet:&emsp; közlekedési alapismeretek számítógépes vizsga. 1 alkalom.<br />
            (Kivéve, ha 2 évnél fiatalabb az A1 jogosítvány és annak KRESZ vizsgája)<br/>
            Gyakorlat:&emsp; járműkezelési (rutin) és forgalmi gyakorlati vizsga. 2 alkalom.<br/>
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            A vizsgára bocsátás feltételei
          </p>
          <p className='low-title-content2'>
            Elméleti vizsgára az bocsátható, aki a tanfolyam elméleti részét igazoltan elvégezte; 18 évesnél legfeljebb 3
            hónappal fiatalabb. <br/>
            Gyakorlati vizsgára az bocsátható, aki elméleti vizsgatárgyból sikeresen vizsgázott(kivéve, ha 2 évnél fiatalabb az
            A1 jogosítvánnyal rendelkezik és annak KRESZ vizsgája is 2 évnél fiatalabb); a kötelező gyakorlati órákat
            igazoltan teljesítette; a 16 évet betöltötte, kivéve járműkezelés vizsga.<br/>
            Korlátozás: Az elsőként szerzett nemzetközi kategóriás vezetői engedély a vizsga napjától számított két évig kezdő vezetői
            engedélynek minősül. A2 kategóriára érvényesített kezdő vezetői engedéllyel utas nem szállítható.<br/>
            A tanuló az öszes vizsgadíjat, illetve tandíjat, csak és kizárólag a képzőszervnél rendezheti!
          </p>
          </Fade>
          <Fade bottom>
          <MyTable3 data={data3} />
            <br />
          <p className='low-title-content2'>
            Átvett tanulók esetében a gyakorlati óradíj 11 000 Ft!
          </p>
          <p className='low-title-content2'>
            Járműkezelési vizsgaóra díja 9.000 Ft.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat'>
            „A” kategória (korlátlan teljesítményű motorkerékpár)
          </p>
          <p className='low-title-content2'>
          A” kategóriával vezethető az a motorkerékpár, amely nem tartozik az „A1” és „A2” alkategóriába teljesítmény
          korlátozás nélkül, valamint az „A” korlátozott kategóriába, továbbá motoros triciklik, amelyek teljesítménye a 15
          kW-ot meghaladja. Az orvosi érvényesség lejártát követően, de legfeljebb az engedély kiállítását követő tíz évig
          az „AM” kategória, a lassú jármű és pótkocsija, a kerti traktor és az állati erővel vont jármű szintén vezethető.
          Tanfolyamra az vehető fel, aki:<br/>
          &emsp;• betöltötte a 24. életévét<br/>
          &emsp;• igazolja alapfokú iskolai végzettségét<br/>
          &emsp;+ A1 és A2 kategóriával rendelkezők számára eltérő oktatási tanrend van, a következők szerint<br/>
          Ha 2 évnél fiatalabb a meglévő A1 vagy A2 jogosítvány és annak KRESZ vizsgája, akkor KRESZ eleméleti képzésen
          és KRESZ elméleti vizsgán nem kell részt venni, csak gyakorlati oktatás van, illetve a gyakorlati kötelező
          alapoktatás (rutin) is kevesebb, eltérő óraszámban<br/>
          Ha 2 évnél fiatalabb a meglévő A1 jogosítvány, akkor 6 óra a járműkezelés (rutin) és 10 óra a forgalmi vezetés,
          minimum 240 km-rel.<br/>
          Ha 2 évnél fiatalabb a meglévő A2 jogosítvány, akkor 4 óra a járműkezelés (rutin) és 8 óra a forgalmi vezetés,
          minimum 180 km-rel.<br/>
          Ha 2 évnél régebbi a meglévő A1 vagy A2 jogosítvány és annak KRESZ vizsgája, akkor KRESZ eleméleti képzésen
          3 órában! - és KRESZ elméleti vizsgán kell újra részt venni, és eltérőek (kevesebb) a kötelező gyakorlati oktatás
          óraszámai (rutin és forgalom), az alábbiak szerint.<br/>
          Ha 2 évnél régebbi a meglévő A1 jogosítvány, akkor 4 óra a járműkezelés (rutin) és 6 óra a forgalmi vezetés,
          minimum 150 km-rel.<br/>
          Ha 2 évnél régebbi a meglévő A2 jogosítvány, akkor 2 óra a járműkezelés (rutin) és 6 óra a forgalmi vezetés,
          minimum 120 km-rel.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            Az tanfolyam tantárgyai, minimális óraszámai
          </p>
          <MyTable5 data={data8}/>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            Vizsgák
          </p>
          <p className='low-title-content2'>
            Elmélet:&emsp; közlekedési alapismeretek számítógépes vizsga. 1 alkalom.<br />
            (Kivéve, ha 2 évnél fiatalabb az A1, vagy A2 jogosítvány és annak KRESZ vizsgája)<br/>
            Gyakorlat:&emsp; járműkezelési (rutin) és forgalmi gyakorlati vizsga. 2 alkalom.<br/>
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat2'>
            A vizsgára bocsátás feltételei
          </p>
          <p className='low-title-content2'>
            Elméleti vizsgára az bocsátható, aki a tanfolyam elméleti részét igazoltan elvégezte; 24 évesnél legfeljebb 3
            hónappal fiatalabb. <br/>
            Gyakorlati vizsgára az bocsátható, aki elméleti vizsgatárgyból sikeresen vizsgázott(kivéve, ha 2 évnél fiatalabb az
            A1, vagy A2 jogosítvánnyal rendelkezik és annak KRESZ vizsgája is 2 évnél fiatalabb); a kötelező gyakorlati órákat
            igazoltan teljesítette; a 24. évet betöltötte, kivéve járműkezelés vizsga.<br/>
            Korlátozás: Az elsőként szerzett nemzetközi kategóriás vezetői engedély a vizsga napjától számított két évig kezdő vezetői
            engedélynek minősül. Az "A" kategóriára érvényesített kezdő vezetői engedéllyel utas nem szállítható.<br/>
            A tanuló az öszes vizsgadíjat, illetve tandíjat, csak és kizárólag a képzőszervnél rendezheti!
          </p>
          </Fade>
          <Fade bottom>
          <MyTable4 data={data4} />
          <p className='low-title-content2'>
            Járműkezelési vizsgaóra díja 9.000 Ft.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat'>
            Tanfolyamra felvétel további személyi feltételei, minden kategóriánál
          </p>
          <p className='low-title-content2'>
          &emsp;- külföldi állampolgárok legalább 180 napos lakcímkártyával igazolt magyarországi tartózkodása esetén jelentkezhetnek.<br/>
          &emsp;- képzési szerződést köt és átveszi a jelen tájékoztatót és vállalkozási feltételt; 18 évesnél fiatalabb jelentkező
          esetén, az autósiskolával kötött szerződést a szülő, törvényes képviselő is aláírja.
          Felelősségvállalási nyilatkozatot tesz, a motor használatról, amit 18 évnél fiatalabb gépjárművezető jelölt
          esetében a szülő, törvényes képviselő is aláír. Tanulóként meghatalmazza a képzőszervet adatainak kezelésére, a tanfolyam és vizsgaügyintézés során.<br/>
          &emsp;- rendelkezik 1. csoportú egészségi alkalmassági igazolással, 13/1992. (VI. 26.) NM rendelet szerint, (kivéve AM kat., illetve meglévő és érvényes B, A1 vagy A2 kategória esetén)<br/>
          &emsp;- ha van vezetői engedélye más kategóriából, annak igazolása az autósiskolának; az elméleti és gyakorlati
          vizsgákon érvényes személyi igazolványt és a már meglévő vezetői engedélyt be kell mutatni! Ha a képzés során
          okmánycsere történik, az új okmányt be kell jelenteni az autósiskolánál és a KAVK – vizsgáztatót szervnél.<br/>
          &emsp;- a tanuló a 326/2011. Korm. rendeletben meghatározott közlekedésbiztonsági feltételeknek megfelel;
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat'>
            Tanfolyamra jelentkezés módja
          </p>
          <p className='low-title-content2'>
          Az ügyfélfogadó helyiségeinkben személyesen, továbbá e-mailben, online és telefonon is kezdeményezheti a képzést.
          A tanfolyamra való felvétel előtt a képzőszerv a tanulóval írásbeli szerződést köt. 
          Fiatalkorú tanuló esetén a kötött szerződést és felelősségvállalási nyilatkozatota törvényes képviselőnek 
          alá kell írnia. A jelentkezési lapot a tanuló önállóan tölti ki és írja alá.<br/>
          Közúti elsősegély vizsgaigazolással kell rendelkezni a vezetői engedély átvételéhez (kivéve AM kat., illetve
          meglévő és érvényes B, A1 vagy A2 kategória esetén); a vizsgáztatás a Magyar Vöröskereszt szervezésében folyik.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat'>
            A vizsgára bocsátás további feltételei
          </p>
          <p className='low-title-content2'>
          <b>Elméleti vizsgára az bocsátható</b>, aki a tanfolyamot sikeresen elvégezteforgalmi vizsgára bocsátás 
          kategóriás életkori határidejénél legfeljebb 3 hónappal fiatalabb. 
          <b>A tanuló atanfolyam kezdetétől számított 9 hónapon belül egy vizsgaeseménynek kell történnie, 
          illetőleg ugyanezen időponttól számított 12 hónapon belül sikeres elméleti vizsgát kell tennie, 
          különben a tanfolyam érvényét veszti és azt
          meg kell ismételni.</b> A tanulónak az elméleti vizsgadíjat a képzési helyszínen kell befizetnie.
          A forgalmi vizsgán a magyar nyelvet nem beszélő külföldi állampolgár tolmács közreműködését igényelheti.
          A sikeres forgalmi vizsgát követő munkanapon a vizsgaközpont az elektronikus vizsgaigazolását megküldi a
          jogosítványt kiállító hivatal részére.<br/>
          A forgalmi vizsgát a sikeres közlekedési alapismereti vizsga leteltének időpontjától számított két éven belül tehet.<br/>
          A sikeres elméleti, közlekedési alapismereti vizsga két évig érvényes.<br/>
          Aki a képzésre jogszabályban is előírt elméleti óraszámot nem teljesíti, vizsgára nem bocsátható. A hiányzást
          pótolni kell a képzési szerv által meghatározott feltételekkel és időpontban, leggyakrabban a soron következő
          elméleti tanfolyamon vagy új online tananyag igénylésével.<br/>
          A közúti járművezetők előírt PÁV alkalmassági vizsgálatait a 41/2004 (IV.7) GKM rendelet, az egészségi
          alkalmasság megállapítását a 13/1992 (VI.26) NM rendelet tartalmazza.<br/>
          A gyakorlati órák időtartama 50 perc.<br/>
          Az online hatósági vizsgafelkészítő és az akkreditált teljes körű hatósági, távoktatású e-learning képzés
          szolgáltatója az e-Educatio Információtechnológia Zrt. A beiratkozás után 90 napon belül az online képzést meg
          kell kezdeni. 90 napon túl újbóli tandíjfizetéssel kezdhető meg az online képzés.<br/>
          <b>Mentesítések:</b> Az egyes tantárgyak hallgatása és vizsgája alóli mentesítés feltételeit a 24/2005 (IV.21) GKM
          rendelethez tartozó 3. sz. melléklet állapítja meg.<br/>
          <b>Áthelyezés:</b> A tanuló áthelyezéséhez a tanuló kezdeményezésére – az elbocsátó szerv által biztosított – megfelelő
          nyomtatványt 3 példányban hiteles adatokkal kell kitölteni. A kitöltött példányokból kettőt vagy a kérelmezőnek
          kell átadni, vagy 10 napon belül a fogadó szervhez kell eljuttatni. A kiállítás megtagadásával a tanuló másik
          képzőszervhez való áthelyezését megakadályozni nem szabad. Az áthelyezés a Balaton Autósiskolánál mindig
          díjtalan.<br/>
          A közlekedési hatóság vizsgaigazolást állít ki, amelyet a sikeres vizsgát követő három munkanapon belül
          elektronikus úton továbbít az illetékes közlekedési igazgatási hatóság részére.<br/>
          <b>A képzési szerződés megszűnése:</b><br/>
          &emsp;- a szerződés megszűnik annak teljesítésével;<br/>
          &emsp;- vagy a tanuló halálával;<br/>
          &emsp;- vagy egészségi alkalmasságának végleges elvesztésével;<br/>
          &emsp;- vagy a törvényi határidők lejártával;<br/>
          &emsp;- vagy a képzési határidők lejártával;<br/>
          &emsp;- vagy a képző szerv írásos és indoklással ellátott felmondásával;<br/>
          &emsp;- vagy a jogszabályi keretek és feltételek megváltozásával, amely a teljesítést ellehetetleníti;<br/>
          &emsp;- vagy az ellenőrző hatóság bármely döntése, amely a teljesítést ellehetetleníti;<br/>
          &emsp;- vagy a tanuló kérelmére kiállításra került képzési igazolás átadásával, amivel a tanuló másik képzőintézményt
          választ.<br/>
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat'>
          A vizsgázó jogai és kötelességei
          </p>
          <p className='low-title-content2'>
            <b>A tanuló jogai:</b><br/>
            &emsp;• Az első sikeres közlekedési alapismereti vizsgától számított 2 éven belül az összes vizsgát teljesíteni.<br/>
            &emsp;• A két év elteltével fél éven belül tanfolyammentes vizsgát tenni, mellyel az érvényesség újabb 2 évvel meghosszabbodik<br/>
            &emsp;• A képzés megkezdése előtt tájékozódni és megismerni a képzőszerv vállalkozási feltételeit. <br/>
            &emsp;• A vállalkozási feltétel valamennyi pontjában ígért szolgáltatást, a kepzősszerven számon kérni és igénybe venni.<br/>
            &emsp;• A képzést megszakítani, a pénzét a meghatározott feltételek mellett visszakérni és a tanfolyam elvégzett részéről hivatalos igazolást kérni.<br/>
            &emsp;• A gyakorlati vezetés időpontjait oktatójával egyeztetni.<br/>
            &emsp;• Az esetleges vélt vagy valós hiányosságokat, illetve a nem megfelelő szolgáltatást a képzőszervnek jelezni, illetve a polgári jog általános szabályai szerint eljárni.<br/>
            &emsp;• Titoktartást követelni a képzőszervtől és az oktatójától egyaránt a személyiséget érintő kérdésekben.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat'>
          A tanuló kötelességei
          </p>
          <p className='low-title-content2'>
            &emsp;• Akadályoztatás esetén a megbeszélt gyakorlati órákat legalább 48 órával előtte lemondani.<br/>
            &emsp;• A tandíj összegét előre készpénzben vagy utalással megfizetni. (Az iskola váltópénzt nem kezel.)<br/>
            &emsp;• A képzőszerv iskolai és pénzügyi rendtartását elfogadni és betartani. <br/>
            &emsp;• Elméleti és gyakorlati foglalkozásokon józan és kipihent állapotban és az oktatási körülményekhez igazodó öltözetben megjelenni.<br/>
            &emsp;• Az általános emberi normákat megtartani és a foglalkozásokon kulturáltan viselkedni, azokon aktívan közreműködni.<br/>
            &emsp;• A tanfolyami órákra felkészülni és az oktatói utasításokat követni.<br/>
            &emsp;• Hiányzás esetén a kieső órák díját megfizetni.<br/>
            &emsp;• Mulasztásait, hiányzásait pótolni.
          </p>
          </Fade>
          <Fade bottom>
          <p className='bcat'>
          A képzőszerv kötelességei
          </p>
          <p className='low-title-content2'>
            &emsp;• A képzés megkezdése előtt a tanulót teljes körűen tájékoztatni a képzés feltételeiről és a tájékoztató írásos példányát átadni. <br/>
            &emsp;• A tanulóval vagy fiatalkorú esetén a törvényes képviselőjével írásos szerződést kötni.<br/>
            &emsp;• A tanuló adatait bizalmasan kezelni. <br/>
            &emsp;• Tandíjbefizetésről számlát adni.<br/>
            <b>Panaszával a felügyeleti szervünkhöz is fordulhat:</b><br/>
            KAV KÖZLEKEDÉSI ALKALMASSÁGI ÉS VIZSGAKÖZPONT NONPROFIT KORLÁTOLT FELELŐSSÉGŰ TÁRSASÁG
            FELÜGYELETI ÉS MÓDSZERTANI IGAZGATÓSÁG<br/>
            EREDMÉNYES TANULÁST KÍVÁNUNK!
          </p>
          </Fade>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}
export default ACategory;