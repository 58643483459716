import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthProvider';
import { db } from './Firebase';
import { doc, setDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Alert, ProgressBar } from 'react-bootstrap';

const Canceled = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [progress, setProgress] = useState(0);
  
    useEffect(() => {
      const canceledSubscription = async () => {
        const queryParams = new URLSearchParams(location.search);
        const uid = queryParams.get('uid');
        const sessionId = queryParams.get('session_id');
  
        if (uid && sessionId) {
          try {
            await setDoc(doc(db, 'subscriptions', uid), { isSubscribed: false }, { merge: true });
            console.log("Subscription canceled for UID:", uid);
          } catch (error) {
            console.error('Error canceling subscription:', error);
          }
        } else {
          console.error('UID or Session ID missing in URL parameters');
        }
      };
  
      canceledSubscription();

      const countdownInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(countdownInterval);
            return 100;
          }
          return prev + 100 / 5;
        });
      }, 1000);

      const timer = setTimeout(() => {
        navigate('/live');
        window.location.reload();
      }, 5000);

      return () => clearTimeout(timer);
    }, [location, user, navigate]);
  
    return (
      <Container className="mt-5 mb-5">
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <Alert variant="warning">
              <h1 className="display-4">Előfizetés megszakadt!</h1>
              <p>Az előfizetési folyamat megszakadt. Az oldal 5 másodpercen belül átirányít.</p>
              <p>Kérjük, ne frissítse és ne zárja be az oldalt.</p>
            </Alert>
            <ProgressBar now={progress} label={`${Math.round(progress)}%`} animated />
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default Canceled;