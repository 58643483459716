import React, { useState } from 'react';
import { useAuth } from './AuthProvider';

export const handleCheckout = async (user) => {
  try {
    const response = await fetch('https://us-central1-balaton-autosisklola-live.cloudfunctions.net/createSimplePaySession', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uid: user.uid }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log("Response", response)
    console.log("Data:", data);

    if (data.paymentUrl) {
      window.location.href = data.paymentUrl;
    } else {
      throw new Error('Failed to obtain payment URL');
    }
  } catch (error) {
    console.error('Error during checkout:', error);
    alert('There was an error during checkout. Please try again.');
  }
};

const CheckoutSimple = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleCheckoutWithLoading = async () => {
    setLoading(true);
    await handleCheckout(user);
    setLoading(false);
  };

  return (
    <div>
      <button onClick={handleCheckoutWithLoading} disabled={loading}>subscribe</button>

      {loading && (
        <div style={modalStyles}>
          <div style={overlayStyles}>
            <div style={dialogStyles}>
              <p>Redirecting to payment url...</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
};

const modalStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const overlayStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
};

const dialogStyles = {
  zIndex: 1001,
  padding: '20px',
  background: 'white',
  borderRadius: '5px',
  textAlign: 'center',
};

export default CheckoutSimple;
