import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyD6727g0DigbUGARzr4jkHUBgiAneIPejE",
  authDomain: "balaton-autosisklola-live.firebaseapp.com",
  projectId: "balaton-autosisklola-live",
  storageBucket: "gs://balaton-autosisklola-live.appspot.com",
  messagingSenderId: "285760561003",
  appId: "1:285760561003:web:2c2b88e78a52abefd1762e",
  measurementId: "G-V951MN106X",
  databaseURL: "https://balaton-autosisklola-live-default-rtdb.europe-west1.firebasedatabase.app",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const database = getDatabase(app);

export { auth, db, database };
