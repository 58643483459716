import React, { useEffect } from 'react';
import { ref, set } from 'firebase/database';
import { database } from './Firebase';

const ShareLocation = () => {
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          const activeStreamRef = ref(database, 'active_stream');
          activeStreamRef.once('value', (snapshot) => {
            const streamId = snapshot.val();
            if (streamId) {
              const locationRef = ref(database, `/live_streams/${streamId}/location`);
              set(locationRef, { latitude, longitude });
            }
          });
        },
        (error) => console.error("Error sharing location:", error),
        { enableHighAccuracy: true }
      );
    }
  }, []);

  return (
    <div>
      <h1>Sharing Location</h1>
      <p>Your location is now being shared with viewers.</p>
    </div>
  );
};

export default ShareLocation;
