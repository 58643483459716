import './App.css';
import {
BrowserRouter as Router,
Routes,
Route
} from 'react-router-dom';
import Home from './Pages/Home';
import Teachers from './Pages/Teachers';
import Prices from './Pages/Prices';
import DataPolicy from './Pages/DataPolicy';
import Courses from './Pages/Courses';
import Contact from './Pages/Contact';
import AkoVsm from './Pages/AkoVsm';
import Footer2 from './Components/Footer/Footer2';
import Navbar from './Components/Home/navbar';
import AllamiTamogatas from './Pages/AllamiTamogatas';
import Aszf from './Pages/Aszf';
import BCategory from './Pages/BCategory';
import ElmeletiKepzes from './Pages/ElmeletiKepzes';
import ACategory from './Pages/ACategories';
import { AuthProvider } from './AuthProvider';
import VimeoLiveVideo from './Pages/VimeoLiveVideo';
import Success from './Success';
import Canceled from './Canceled';
import Fail from './Fail';
import Timeout from './Timeout';
import { SuccessMessageProvider } from './SuccessMessageContext';
import ShareLocation from './ShareLocation';

function App() {
  return (
  <SuccessMessageProvider>
    <AuthProvider>
      <div className="App">
        <Navbar />
        <div className='main'>
          <Router>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/oktatok' element={<Teachers />} />
              <Route path='/kurzusok' element={<Courses />} />
              <Route path='/arak' element={<Prices />} />
              <Route path='/kapcsolat' element={<Contact />} />
              <Route path='/adatvedelem' element={<DataPolicy />} />
              <Route path='/akovsm' element={<AkoVsm />} />
              <Route path='/allamitamogatas' element={<AllamiTamogatas />} />
              <Route path='/aszf' element={<Aszf />} />
              <Route path='/bkategoria' element={<BCategory />} />
              <Route path='/elmeletikepzes' element={<ElmeletiKepzes />} />
              <Route path='/akategoriak' element={<ACategory />} />
              <Route path="/live" element={<VimeoLiveVideo />} />
              <Route path="/success" element={<Success />} />
              <Route path="/canceled" element={<Canceled />} />
              <Route path='/fail' element={<Fail />} />
              <Route path='/timeout' element={<Timeout />} />
              <Route path="/share-location" element={<ShareLocation />} />
            </Routes>
          </Router>
        </div>
        <Footer2 />
      </div>
      </AuthProvider>
    </SuccessMessageProvider>
  );
}

export default App;
