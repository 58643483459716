import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from './AuthProvider';
import { db } from './Firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Alert, ProgressBar } from 'react-bootstrap';

const Success = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [progress, setProgress] = useState(0);
    const [transactionId, setTransactionId] = useState(null);
    const [orderRef, setOrderRef] = useState(null);
    const hasRun = useRef(false);
  
    useEffect(() => {
      if(hasRun.current) return;
      hasRun.current = true;

      const confirmSubscription = async () => {
        const queryParams = new URLSearchParams(location.search);
        const uid = queryParams.get('uid');
        const sessionId = queryParams.get('session_id');
  
        if (uid && sessionId) {
          try {
            const subscriptionDoc = await getDoc(doc(db, 'subscriptions', uid));
            if (subscriptionDoc.exists()) {
              const subscriptionData = subscriptionDoc.data();
              setTransactionId(subscriptionData.transactionId);
              setOrderRef(subscriptionData.orderRef);

              const countdownInterval = setInterval(() => {
                setProgress((prev) => {
                  if (prev >= 100) {
                    clearInterval(countdownInterval);
                    return 100;
                  }
                  return prev + 100 / 15;
                });
              }, 1000);

              setTimeout(async () => {
                await fetch(`https://us-central1-balaton-autosisklola-live.cloudfunctions.net/simplePayCallback`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ t: subscriptionData.transactionId, e: "SUCCESS", o: subscriptionData.orderRef }),
                });

                console.log("simplePayCallback called successfully for UID:", uid);
                navigate('/live');
                window.location.reload();
              }, 15000);
          } else {
              console.error("No subscription found for UID:", uid);
          }
          } catch (error) {
            console.error('Error confirming subscription:', error);
          }
        }
      };
  
      confirmSubscription();

    }, [location, user, navigate]);
  
    return (
      <Container className="mt-5 mb-5">
        <Row className="justify-content-center">
          <Col xs={12} md={8}>
            <Alert variant="success">
              <h1 className="display-4">Sikeres előfizetés!</h1>
              <p>Köszönjük, hogy előfizetett! Az oldal automatikusan átirányít 15 másodpercen belül.</p>
              <p>Kérjük, ne frissítse és ne zárja be az oldalt.</p>
            </Alert>
            <ProgressBar now={progress} label={`${Math.round(progress)}%`} animated />
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default Success;