import React, { useState } from 'react';
import { useAuth } from '../../AuthProvider';
import DeleteAccount from '../../DeleteAccount';
import { Form, Button, Container, Card, Modal } from 'react-bootstrap';
import './Login.css';
import { useSuccessMessage } from '../../SuccessMessageContext';

const Login = () => {
  const { loginWithGoogle, loginWithEmail, registerWithEmail } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const { setSuccessMessage } = useSuccessMessage();

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    setShowPopup(false);
    try {
      await loginWithEmail(email, password);
      setSuccessMessage('Sikeres bejelentkezés!');
    } catch (error) {
      setErrorMessage('Helytelen e-mail cím vagy jelszó. Kérjük, próbálja újra.');
      setShowPopup(true);
      console.error('Error logging in with email: ', error);

      setTimeout(() => {
        setShowPopup(false);
      }, 5000);
    }
  };

  const handleEmailRegister = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    setShowPopup(false);
    if (!acceptedTerms) {
      alert("Kérjuk fogadja el az Általános Szerződési Feltételeket!");
      return;
    }
    try {
      await registerWithEmail(email, password);
      setSuccessMessage('Sikeres regisztráció!');
    } catch (error) {
      const errorCode = error.code;
      if (errorCode === 'auth/email-already-in-use') {
        setErrorMessage('Ez az email cím már regisztrálva van.');
      } else if (errorCode === 'auth/invalid-email') {
        setErrorMessage('Érvénytelen email cím. Kérjük, adjon meg egy helyes email címet.');
      } else if (errorCode === 'auth/weak-password') {
      setErrorMessage('A jelszónak legalább 6 karakter hosszúnak kell lennie.');
      } else {
        setErrorMessage('Regisztráció sikertelen. Próbálja újra.');
      }
      setShowPopup(true);
      console.error('Error registering with email: ', error.code);

      setTimeout(() => {
        setShowPopup(false);
      }, 5000);
    }
  };

  const handleValidation = (e) => {
    if (!e.target.value) {
      e.target.setCustomValidity("A mező kitöltése kötelező!");
    } else {
      e.target.setCustomValidity("");
    }
  };

  return (
    <Container className="container">
      <Card className="card">
        <Card.Body>
        <h2 className="title mb-4">Bejelentkezés</h2>
          <Button 
            variant="primary" 
            className="w-100 mb-3"
            onClick={loginWithGoogle}
          >
            Bejelentkezés Google fiókkal
          </Button>

          <Form onSubmit={handleEmailLogin}>
            <Form.Group controlId="emailLogin">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="pelda@gmail.com" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                onInvalid={handleValidation}
                onInput={handleValidation}
                required
              />
            </Form.Group>

            <Form.Group controlId="passwordLogin" className="mt-3">
              <Form.Label>Jelszó</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Jelszó" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)}
                onInvalid={handleValidation}
                onInput={handleValidation}
                required
              />
            </Form.Group>

            <Button type="submit" variant="primary" className="w-100 mt-4">
              Bejelentkezés
            </Button>
          </Form>

          <h2 className="title2 mb-4">Regisztráció</h2>
          <Form onSubmit={handleEmailRegister}>
            <Form.Group controlId="emailReg">
              <Form.Label>Email</Form.Label>
              <Form.Control 
                type="email" 
                placeholder="pelda@gmail.com" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
                onInvalid={handleValidation}
                onInput={handleValidation}
                required
              />
            </Form.Group>

            <Form.Group controlId="passwordReg" className="mt-3">
              <Form.Label>Jelszó</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Jelszó" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)}
                onInvalid={handleValidation}
                onInput={handleValidation}
                required
              />
            </Form.Group>

            <Form.Group controlId="terms" className="mt-3">
              <Form.Check 
                type="checkbox" 
                label={
                  <>
                    Elfogadom az <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => setShowModal(true)}>Általános Szerződési Feltételeket.</span>
                  </>
                } 
                checked={acceptedTerms} 
                onChange={(e) => setAcceptedTerms(e.target.checked)} 
                required
              />
            </Form.Group>

            <Button type="submit" variant="primary" className="w-100 mt-4" disabled={!acceptedTerms}>
              Regisztráció
            </Button>
          </Form>
          
          <DeleteAccount />
        </Card.Body>
      </Card>

      {showPopup && (
        <div className="error-popup">
          <div className="popup-content">
            {errorMessage}
          </div>
        </div>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Általános Szerződési Feltételek</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Amely létrejött a Balaton Autósiskola Kft., mint szolgáltató és a megrendelő között.</p>
          <p>Jelen szerződés elfogadásával a megrendelő tudomásul veszi,hogy az online oktatást illetve kresz táv oktatását 3. fél számára nem adja ki. Felvételt nem készít róla.</p>
          <p>Az élő közvetítéseken szereplők beleegyeztek a kép, hanganyag készítésébe.</p>
          <p>A megrendelő beleegyezik, hogy a vásárlás után járó számlát elektronikus formában 48 órán belül megkapja.</p>
          <p>Tudomásul veszem, hogy a(z) Balaton Autósiskola Kft. (8600, Siófok Kristály utca 39.) adatkezelő által a(z)
          autosiskolabalaton.hu felhasználói adatbázisában tárolt alábbi személyes
          adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az
          adatkezelő által továbbított adatok köre az alábbi: Név, email, irányítószám, város, utca, ország, telefonszám
          Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a
          SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:
          <a href='http://simplepay.hu/vasarlo-aff'>http://simplepay.hu/vasarlo-aff</a></p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Bezárás
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Login;
