import React, { useEffect, useState } from 'react';
import { useAuth } from '../AuthProvider';
import Login from '../Components/Login/Login';
import LiveEventsCalendar from '../Components/Calendar/LiveEventsCalendar';
import { useSuccessMessage } from '../SuccessMessageContext';
import { Container, Row, Col, Card, Button, Spinner, Alert, Form, Modal } from 'react-bootstrap';
import '../Css/VimeoLiveEmbed.css';
import { handleCheckout } from '../CheckoutSimple';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { ref, onValue } from 'firebase/database';
import { database } from '../Firebase';

const VimeoLiveEmbed = () => {
  const [videoId, setVideoId] = useState('');
  const [error, setError] = useState('');
  const { user, hasAccess, loading } = useAuth();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const { successMessage, setSuccessMessage } = useSuccessMessage();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const { logout } = useAuth();

  useEffect(() => {
    if (successMessage) {
      const timeout = setTimeout(() => setSuccessMessage(''), 5000);
      return () => clearTimeout(timeout);
    }
  }, [successMessage, setSuccessMessage]);

  const fetchLiveVideoId = async () => {
    try {
      const response = await fetch(
        `https://us-central1-balaton-autosisklola-live.cloudfunctions.net/getVimeoLiveVideo`
      );
      const data = await response.json();
      console.log("Vimeo API Response:", data);

      if (data.videoId) {
        setVideoId(data.videoId);
      }
    } catch (error) {
      setError(error.message);
      console.error('Error fetching live video:', error);
    }
  };

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const activeStreamRef = ref(database, 'active_stream');
        const snapshot = await get(activeStreamRef);
        
        const streamId = snapshot.val();
        if (streamId) {
          const locationRef = ref(database, `/live_streams/${streamId}/location`);
          onValue(locationRef, (snapshot) => {
            const locationData = snapshot.val();
            if (locationData) {
              setLatitude(locationData.latitude);
              setLongitude(locationData.longitude);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };
  
    fetchLocation();
  }, []);

  useEffect(() => {
    console.log("User:", user);
    console.log("Has Access:", hasAccess);

    if (user && hasAccess) {
      fetchLiveVideoId();
    }
  }, [user, hasAccess]);

  const embedUrl = `https://player.vimeo.com/video/${videoId}?autoplay=1`;
  console.log("Embed URL:", embedUrl);

  const handleSubscribe = async (plan) => {
    if (!acceptedTerms) {
      setErrorMessage("Kérjük, fogadja el az Általános Szerződési Feltételeket a folytatáshoz.");
      return;
    }
    setErrorMessage('');

    setLoadingMessage("Kérjük várjon, amég átirányítjuk a fizetési oldalra...");
    setShowModal(true);

    try {
      await handleCheckout(user);
    } catch (error) {
      setShowModal(false);
      setErrorMessage("Hiba történt a fizetési folyamat során. Kérjük, próbálja újra.");
    }

  };

  return (
    <Container fluid className="vimeo-live-embed-container">
      {successMessage && (
        <Alert variant="success" className="success-popup">
          {successMessage}
        </Alert>
      )}

      {!loading && !user && (
        <Row>
          <Col>
            <Login />
          </Col>
        </Row>
      )}

      {user && (
        <>
          {!hasAccess && (
            <Row className="subscription-section">
              <Col className="subscription-container">
              <Button variant="danger" onClick={() => logout()} className="mt-4">
                  Kijelentkezés
                </Button>
                <h2 className="">Válassza ki az előfizetési csomagját</h2>

                <div className="simplepay-info mb-3">
                  <a href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank" rel="noopener noreferrer">
                    <img
                      width="200"
                      height="50"
                      src="./images/simplepay_logo.png"
                      alt="SimplePay vásárlói tájékoztató"
                      title="SimplePay - Online bankkártyás fizetés"
                    />
                  </a>
                  <p>
                    A fenti logóra kattintva megtekintheti a SimplePay fizetési tájékoztatóját.
                  </p>
                </div>

                {errorMessage && (
                  <Alert variant="danger">
                    {errorMessage}
                  </Alert>
                )}

                <Form.Group controlId="termsCheckbox" className="mb-4">
                  <Form.Check
                    type="checkbox"
                    label="Elfogadom az Általános Szerződési Feltételeket"
                    checked={acceptedTerms}
                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                  />
                </Form.Group>

                <Row className="justify-content-center">
                  <Col sm={10} md={4} className="mb-3">
                    <Card className="subscription-card">
                      <Card.Body>
                        <Card.Title>Napi előfizetés</Card.Title>
                        <Card.Text>Szerezzen hozzáférést 24 órára.</Card.Text>
                        <Button
                          variant="primary"
                          className="w-100"
                          onClick={() => handleSubscribe('daily')}
                        >
                          Előfizetés - 1000Ft/nap
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={10} md={4} className="mb-3">
                    <Card className="subscription-card">
                      <Card.Body>
                        <Card.Title>Heti előfizetés</Card.Title>
                        <Card.Text>Szerezzen hozzáférést 1 hétre.</Card.Text>
                        <Button
                          variant="primary"
                          className="w-100"
                          onClick={() => handleSubscribe('weekly')}
                        >
                          Előfizetés - ....Ft/hét
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={10} md={4} className="mb-3">
                    <Card className="subscription-card">
                      <Card.Body>
                        <Card.Title>Havi előfizetés</Card.Title>
                        <Card.Text>Szerezzen hozzáférést 1 hónapra.</Card.Text>
                        <Button
                          variant="primary"
                          className="w-100"
                          onClick={() => handleSubscribe('monthly')}
                        >
                          Előfizetés - ....Ft/hónap
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false}>
            <Modal.Body>
              <Spinner animation="border" role="status" className="mr-3">
                <span className="sr-only">Betöltés...</span>
              </Spinner>
              {loadingMessage}
            </Modal.Body>
          </Modal>

          {hasAccess && (
            <Row className="calendar-section">
              <Col className="calendar-container">
                {loading && (
                  <div className="loading-spinner">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Betöltés...</span>
                    </Spinner>
                  </div>
                )}

                {videoId && (
                  <><div className="video-container">
                    <Button variant="danger" onClick={() => logout()} className="mt-4">
                      Kijelentkezés
                    </Button>
                    <iframe
                      src={embedUrl}
                      width="100%"
                      height="400px"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                      title="Vimeo Live Video"
                    ></iframe>
                  </div><div style={{ width: '500px', height: '360px' }}>
                      <LoadScript 
                        googleMapsApiKey="AIzaSyDaJAttlVY7QdYb3WoJ5TQJisG3hq8DG1Q"
                      >
                        <GoogleMap
                          mapContainerStyle={{ height: "100%", width: "100%" }}
                          zoom={13}
                          center={{ lat: latitude || 0, lng: longitude || 0 }}
                        >
                        {latitude && longitude && (
                          <Marker position={{ lat: latitude, lng: longitude }} />
                        )}
                        </GoogleMap>
                      </LoadScript>
                    </div></>
                )}

                {!videoId && (
                  <><div className="no-video-available">
                    <Button variant="danger" onClick={() => logout()} className="mt-4">
                      Kijelentkezés
                    </Button>
                    <Card className="text-center no-video-card">
                      <Card.Body>
                        <Card.Title>Nincs elérhető élő közvetítés</Card.Title>
                        <Card.Text>
                          {error || "Jelenleg nincs elérhető élő közvetítés. Kérjük, nézzen vissza később."}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div><div style={{ width: '500px', height: '360px' }}>
                      <LoadScript 
                        googleMapsApiKey="AIzaSyDaJAttlVY7QdYb3WoJ5TQJisG3hq8DG1Q"
                      >
                        <GoogleMap
                          mapContainerStyle={{ height: "100%", width: "100%" }}
                          zoom={13}
                          center={{ lat: latitude || 0, lng: longitude || 0 }}
                        >
                        {latitude && longitude && (
                          <Marker position={{ lat: latitude, lng: longitude }} />
                        )}
                        </GoogleMap>
                      </LoadScript>
                    </div></>
                )}

              </Col>
            </Row>
          )}
        </>
      )}

      <Row className="calendar-section">
        <Col className="calendar-container">
          <LiveEventsCalendar />
        </Col>
      </Row>
    </Container>
  );
};

export default VimeoLiveEmbed;
